<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['โปรโมชั่น', 'จัดการ']" />
    <div class="table-responsive">
      <table
        class="table table-sm table-bordered table-striped table-dark text-wrap"
      >
        <thead>
          <tr>
            <th>#ID</th>
            <th>ชื่อ</th>
            <th>คำอธิบาย</th>
            <th>รูป</th>
            <th>รายละเอียด</th>
            <th>สถานะ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in platformPromotions" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td><img :src="item.imageUrl" width="500" /></td>
            <td v-html="item.detail"></td>
            <td>
              <select
                v-if="user.levelNumber >= 32"
                @change="updatePromotionStatus(item)"
                v-model="item.status"
                class="form form-control"
              >
                <option value="INACTIVE">ปิด</option>
                <option value="ACTIVE">เปิด</option>
              </select>
              <span v-if="user.levelNumber < 32">{{ item.status }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      platformPromotions: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user.userData
    },
    canEdit(){
      return this.$store.state.auth.user?.userData?.permissions?.includes("promotion_detail_edit") 
    }
  },
  methods: {
    async updatePromotionStatus(item) {
      let confirm = await this.swal.confirm(
        "ปรับสถานะโปรโมชั่น",
        "ท่านต้องปรับสถานะโปรโมชั่นใช่หรือไม่"
      )
      if (!confirm) {
        return
      }

      this.swal.processing()

      try {
        await this.axios({
          method: "patch",
          url: "promotion/status",
          data: item,
        })

        await this.$swal({
          icon: "success",
          title: "สำเร็จ",
        })

        this.$store.dispatch("auth/forceReload")
      } catch (e) {
        this.swal.catchError(e)
      }
    },
  },
  async mounted() {
    try {
      const { data } = await this.axios({
        method: "get",
        url: "promotion/platform-promotion",
      })

      this.platformPromotions = data.data
    } catch (e) {
      console.log(e)
      this.swal.error()
    }
  },
}
</script>